import Log from "../utils/log";

export const save = async (key: string, value: any) => {
  if (typeof window === "undefined") return;
  if (typeof localStorage !== "undefined") {
    if (localStorage.getItem(key) !== null) {
      localStorage.removeItem(key);
    }
    return localStorage.setItem(key, JSON.stringify(value));
  }

  Log.info(`Write error ${key} local storage is not ready`);
  return;
};

export const read = (key: string): any => {
  if (typeof window === "undefined") return;
  var obj = {};
  if (typeof localStorage !== "undefined") {
    obj = JSON.parse(localStorage.getItem(key) || "{}");
  } else {
    Log.info(`Read error ${key} local storage is not ready`);
  }
  return obj;
};

export const deepSave = async (key: string, value: any): Promise<any> => {
  if (typeof window === "undefined") return;
  let obj = Object.assign(read(key), value);
  save(key, obj);
  return obj;
};

export const remove = (key: string): void => {
  if (typeof window === "undefined") return;
  if (typeof localStorage !== "undefined") {
    if (localStorage.getItem(key) !== null) {
      localStorage.removeItem(key);
    }
  }
};
