import { hasVariant, increaseVariant } from "../src/contexts/Cart/helpers";
import { CartItem } from "../src/models/Cart";

export const SPECIAL_COUPONS = [
  {
    code: "PIZZAGRAY",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTc0NDY0Mzk4MTQ4Nw==",
    title: "Roccbox Pizza Oven by Gozney",
  },
  {
    code: "PIZZAGREEN",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTc0NDY0NjM0MDc4Mw==",
    title: "Roccbox Pizza Oven by Gozney",
  },
  {
    code: "FARMSTAND",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDQ1NjI5NDMzNDYzOQ==",
    title: "Lettuce Grow 24-Plant Farmstand + Outer Seedling Pac",
  },
  {
    code: "BONFIRE",
    title: "Solo Stove Smokeless Fire Pit",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzkwMTk3MzM4OTQ4Nw==",
  },
  {
    code: "KKHPTN",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMjg1Nzk1OTg2NjQyNg==",
    title: "KKH Voucher",
  },
  {
    code: "LLPTN",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMjg1Nzk2NDAyNzk2Mg==",
    title: "Lawn Love Voucher",
  },
  {
    code: "RUMPL",
    title: "Rumpl Original Puffy Blanket Voucher",
    variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTU3ODQ3NDcwOTA1Mg==",
  },
];

export const SpecialCartActions = {
  BESTSUMMER: (items: CartItem[]) => {
    const Mappings = [
      {
        sets: [
          "38241332658351",
          "38228704198831",
          "39431941128367",
          "38241318568111",
          "39431946829999",
          "38314950066351",
          "38228818067631",
          "38228878393519",
          "38241891680431",
          "38241349697711",
          "38241920024751",
          "38246503940271",
          "38246518292655",
        ],
        chair: "38287323463855", // Teak - Pacific Fog Gray
      },
      {
        sets: [
          "38241332691119",
          "38228704231599",
          "39431941161135",
          "38241318600879",
          "39431946862767",
          "38314950099119",
          "38228818100399",
          "38228878491823",
          "38241891713199",
          "38241349730479",
          "38241920057519",
          "38246503973039",
          "38246518325423",
        ],
        chair: "38287323496623", // Teak - Palisades Cream
      },
      {
        sets: [
          "41391326462127",
          "41391325839535",
          "41391326363823",
          "41391327281327",
          "41391325937839",
          "41391326298287",
          "41391326593199",
          "41391326527663",
          "41391326691503",
          "41391326757039",
          "41391326822575",
        ],
        chair: "41391327215791", // Teak - Dark Pebble Gray
      },
      {
        sets: [
          "41391326429359",
          "41391325806767",
          "41391326331055",
          "41391327248559",
          "41391325905071",
          "41391326265519",
          "41391326560431",
          "41391326494895",
          "41391326658735",
          "41391326724271",
          "41391326789807",
        ],
        chair: "41391327183023", // Teak - Deep Sea Navy
      },
      {
        sets: [
          "31055989538876",
          "31056013426748",
          "31056007397436",
          "38043266285743",
          "16927440568378",
          "38043426422959",
          "38043452768431",
          "23699833225274",
          "31912159477820",
          "31056056877116",
          "31912155349052",
          "32048757309500",
        ],
        chair: "21281271611450", // Brown Wicker - Pacific Fog Gray
      },
      {
        sets: [
          "31055989571644",
          "31056013459516",
          "31056007430204",
          "38043266351279",
          "31326364106812",
          "38043426455727",
          "38043452801199",
          "31326355619900",
          "31912159510588",
          "31056056942652",
          "31912155381820",
          "32048757342268",
        ],
        chair: "21281271578682", // Brown Wicker - Palisades Cream
      },
      {
        sets: [
          "41391403958447",
          "41391404187823",
          "41391404089519",
          "41391405007023",
          "41391403073711",
          "41391405105327",
          "41391405269167",
          "41391403827375",
          "41391404515503",
          "41391404286127",
          "41391404417199",
          "41391404646575",
        ],
        chair: "41391403499695", // Brown Wicker - Dark Pebble Gray
      },
      {
        sets: [
          "41391403925679",
          "41391404155055",
          "41391404056751",
          "41391404974255",
          "41391403040943",
          "41391405072559",
          "41391405236399",
          "41391403794607",
          "41391404482735",
          "41391404253359",
          "41391404384431",
          "41391404613807",
        ],
        chair: "41391403466927", // Brown Wicker - Deep Sea Navy
      },
      {
        sets: [
          "41391403991215",
          "41391404220591",
          "41391404122287",
          "41391405039791",
          "41391403106479",
          "41391405138095",
          "41391405301935",
          "41391403860143",
          "41391404548271",
          "41391404318895",
          "41391404449967",
          "41391404679343",
        ],
        chair: "41391403532463", // Brown Wicker - Sandstone Gray
      },
    ];

    let variantId = null as string | null;

    const transformVariantId = (vid: string) => {
      vid = "gid://shopify/ProductVariant/" + vid;
      return btoa(vid);
    };

    Mappings.forEach(mapping => {
      mapping.sets.forEach(set => {
        if (hasVariant(items, transformVariantId(set))) {
          variantId = transformVariantId(mapping.chair);
        }
      });
    });

    if (variantId) {
      items = increaseVariant(items, {
        id: variantId,
        title: "Complimentary Armless Chair",
        image: "",
        product: {
          id: "",
          title: "Complimentary Armless Chair",
          slug: "",
          price: 0,
          remoteId: "",
          outOfStock: false,
          photos: [],
        },
        price: 0,
      });
    }
    return items;
  },
};

export const RESET_CART_BEFORE = 1608612650;
