const constructPrefix = level => {
  return ["OUTER", ":", level.toUpperCase()].join(":");
};
module.exports = {
  info: (...data) => {
    if (
      process.env["NODE_ENV"] !== "production" ||
      process.env["GATSBY_BRANCH"] !== "production"
    ) {
      console.log(constructPrefix("info"), ...data);
    }
  },
  warn: (...data) => {
    if (
      process.env["NODE_ENV"] !== "production" ||
      process.env["GATSBY_BRANCH"] !== "production"
    ) {
      console.warn(constructPrefix("warn"), ...data);
    }
  },
  error: (...data) => {
    console.log(constructPrefix("error"), ...data);
  },
};
