import React, { useContext } from "react";

interface MainStateProps {
  navbar: {
    height: number;
    headerBannerHeight: number;
    headerBannerDisplay: boolean;
    stickyCtaData: Record<string, any>;
    navbarOnHero: boolean;
    navbarStyle: "dark" | "light";
    moreProductsButtonOpen: boolean;
    hideNavbar: boolean;
  };
  sessionData: Record<string, any>;
  paymentData: {
    scriptLoaded: boolean;
  };
  fireworkData: {
    isFireworkAgentAvailable: boolean;
  };
  promotion: {
    enable: boolean;
  };
}

export interface DispatchMainStateActionsProps {
  (action: Record<string, unknown>): void;
}

export interface MainStateStoreProps {
  state: MainStateProps;
  dispatch: DispatchMainStateActionsProps;
}

const defaultState: MainStateProps = {
  navbar: {
    height: 0,
    headerBannerHeight: 0,
    headerBannerDisplay: true,
    stickyCtaData: {},
    navbarOnHero: false,
    navbarStyle: "dark",
    moreProductsButtonOpen: false,
    hideNavbar: false,
  },
  sessionData: {},
  paymentData: {
    scriptLoaded: false,
  },
  fireworkData: {
    isFireworkAgentAvailable: false,
  },
  promotion: {
    enable: false,
  },
};

const MainContext = React.createContext<MainStateStoreProps>({
  state: defaultState,
  dispatch: () => {},
});

const mainReducer = (state, action) => {
  switch (action.type) {
    case "setNavbarData": {
      return {
        ...state,
        navbar: {
          ...state.navbar,
          ...action.navbar,
        },
      };
    }
    case "setSessionData": {
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          ...action.sessionData,
        },
      };
    }
    case "setPaymentData": {
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          ...action.paymentData,
        },
      };
    }
    case "setPaymentData": {
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          ...action.paymentData,
        },
      };
    }
    case "setFireworkData": {
      return {
        ...state,
        fireworkData: {
          ...state.fireworkData,
          ...action.fireworkData,
        },
      };
    }
    case "setPromotionOn": {
      return {
        ...state,
        promotion: {
          ...state.promotion,
          enable: action.promotion.enable,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type ${action.type}`);
    }
  }
};

export const useMainContext = () => useContext(MainContext);

const MainProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(mainReducer, defaultState);

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;

export { MainProvider };
