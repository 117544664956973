import React from "react";
import { ApolloProvider } from "@apollo/client";
import { CartProvider } from "../../contexts/Cart/Cart.provider";
import { MainProvider } from "../../contexts/Main/Main.context";
import { ShowroomsProvider } from "../../contexts/Showrooms/Showrooms.context";
import { UserProvider } from "../../contexts/User/User.context";
import { FormsProvider } from "../../contexts/Forms/Forms.context";
import { client } from "../../apollo/apollo.config";
import { HelmetProvider } from "react-helmet-async";

export const wrapRootElement = ({ element }) => {
  const helmetContext = {};
  return (
    <>
      <ApolloProvider client={client}>
        <HelmetProvider context={helmetContext}>
          <ShowroomsProvider>
            <UserProvider>
              <CartProvider>
                <FormsProvider>
                  <MainProvider>{element}</MainProvider>
                </FormsProvider>
              </CartProvider>
            </UserProvider>
          </ShowroomsProvider>
        </HelmetProvider>
      </ApolloProvider>
    </>
  );
};
