// TODO: stateHash should this should be queryable at compile time
export const stateHash = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const regionsHash = [
  {
    name: "Northwest",
    states: ["AK", "WA", "OR", "ID"],
  },
  {
    name: "West",
    states: ["CA", "NV", "HI"],
  },
  {
    name: "Southwest",
    states: ["UT", "CO", "AZ", "NM"],
  },
  {
    name: "Northern Rockies / Plains",
    states: ["MT", "ND", "WY", "NE", "SD"],
  },
  {
    name: "Upper Midwest",
    states: ["MN", "WI", "MI", "IA"],
  },
  {
    name: "Central",
    states: ["MO", "IL", "IN", "OH", "WV", "KY", "TN", "WV"],
  },
  {
    name: "South",
    states: ["KS", "OK", "TX", "AR", "MS", "LA"],
  },
  {
    name: "Southeast",
    states: ["VA", "NC", "SC", "GA", "FL", "AL"],
  },
  {
    name: "Northeast",
    states: ["ME", "VT", "NH", "NY", "PA", "MA", "RI", "CT", "NJ", "DE", "MD"],
  },
];

export const UnitedStates = {
  abbreviations: Object.keys(stateHash),
  states: Object.values(stateHash),
  fromAbbreviation: abbr => {
    return stateHash[abbr.toUpperCase()];
  },
  fromFullName: state => {
    return Object.keys(stateHash).find(
      k => stateHash[k] === state || stateHash[k].toLowerCase() === state
    );
  },
};

export const phoneInternationalCode = "1";

export const usRegionalCopy = {
  phoneNumberValidationRules: {
    required: true,
    pattern: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[ ]*[-s./0-9]{6,}$",
    min: 6,
    message: "Please enter a valid ten digit U.S. phone number",
    validateTrigger: "onSubmit",
  },
  distanceUnit: "mile" as "mile" | "km",
  displayDistance: "mi",
  stateProvinceText: "State",
  cityText: "City",
  zipCodeText: "Zip Code",
  supportEmail: "support@liveouter.com",
  ordersEmail: "orders@liveouter.com",
  teamEmail: "team@liveouter.com",
  contactNumber: `+${phoneInternationalCode}8552777744`,
  variantLabels: {
    byColor: "Color - ",
    bySize: "Size - ",
    byDollarAmount: "Amount - ",
    byOption: "",
  },
};

// key should be {componentName}--{descriptor} - this is so in the future
// should this be moved out into JSON or individual files/etc this can still be managed
export const usTextTranslations = {
  "ModalStep--good-fit-input":
    "Tell us why you and your outdoor space are a good fit for our Neighborhood Showroom® program (optional).",
  "ModalStep--custom-button-text": "Shop Configurations",
  "DetailPageSidebar--description":
    "Outer’s Neighborhood Showrooms® are located at the homes of real Outer customers in your area.",
  "NoResults--cta-text":
    "Interested in hosting a Neighborhood Showroom®? Apply today and earn 10% off of your first purchase.",
  "ShowroomCard--tooltip-condition": "Neighborhood Showroom®",
  "ShowroomDetails--other-showroom-header": "Other Neighborhood Showrooms®",
  "ShowroomDetails--tooltip-condition": "Neighborhood Showroom®",
  "ActionSection--tooltip-condition": "Neighborhood Showroom®",
  "InfoSection--info-text":
    "Outer’s Neighborhood Showrooms® are located at the homes of real Outer customers in your area. For privacy, the exact location will be provided to you by an Outer Concierge after you submit your request to connect.",
  "Covid19--nbs-header": "Neighborhood Showroom®",
  "Covid19--li-one-text":
    "For the time being, we have suspended in-person showroom visits to our Neighborhood Showrooms® to ensure the safety of our community.",
  "Covid19--li-three-text":
    "We're actively accepting applications for Neighborhood Showroom® Hosts! Apply today to host a virtual showroom and receive 10% off the purchase of your outdoor sofa.",
  "About--values-description":
    "Our customers, our team, our families, our friends and our neighbors. These people are at the center of everything that we do. We made Outer for all of us, because life is meant to be shared.",
  "About--approach-nbs-subtitle": "The Neighborhood Showroom®",
  "About--community-description":
    "We aim to connect people from all neighborhoods and all walks of life. We’re a collection of familiar and unfamiliar faces, bound together by our values and a sense of pride around makes each of us unique.",
  "QuizData--frame-style-options": [
    "Teak Frame Style",
    "Wicker Frame Style",
    "Aluminum Frame Style",
  ],
  "QuizData--frame-style-slugs": [
    "teak-outdoor-sofa-three-seat",
    "the-three-piece",
    "aluminum-outdoor-sofa-three-seat",
  ],
  "QuizData--fabric-preference-title": "What color fabric do you prefer?",
  "QuizData--fabric-preference-options": [
    "Pacific Fog Gray",
    "Palisades Cream",
  ],
  "QuizWizard--getQuizStepImages-color": "Pacific Fog Gray",
  "ConfigurationQuiz--frame-options": ["teak", "wicker", "aluminum"],
  "ConfigurationQuiz--color-options": ["Pacific Fog Gray", "Palisades Cream"],
  "Filters--location-autocomplete-placeholder":
    "Search by city, address or zip code",
  "NewsletterForm--zipcode-input-placeholder": "Enter Zip code...",
  "ImagePanSlide--sections-01-content":
    "Our custom performance fabric is stain, fade and mold resistant yet still soft to the touch.",
  "CustomProductsCard--preview-card-color": "Color",
  "CartItems--variant-type-text-by-color": "Color",
  "VariantSelector--choose-fabric-p": "Choose your fabric color:",
  "ProductDimensions--variant-type-color": "color",
  "Explore1188EcoFriendlyOutdoorRugs--rugprops-subtitle":
    "Three colors and sizes to choose from",
  "Filters--aluminum": "aluminum",
  "Filters--White Aluminum": "White Aluminum",
  "Filters--Charcoal Aluminum": "Charcoal Aluminum",
  "Filters--Color": "Color",
  "ColorSelector--header-text": "Select a Fabric Color",
  "ColorSelector--header-standard-text": "Color",
};

export const usAddlConfig = {
  klayvioPrefix: "list",
  bnplProvider: "affirm",
};
