import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  split,
  from,
  ApolloLink,
} from "@apollo/client";

import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "isomorphic-fetch";

const ALAMEDA_HOST = process.env["GATSBY_ALAMEDA_API_ENDPOINT"]
  ? process.env["GATSBY_ALAMEDA_API_ENDPOINT"]
  : "https://alameda.liveouter.com/graphql";

const SHOPIFY_API_ENDPOINT = process.env.GATSBY_SHOPIFY_API_ENDPOINT
  ? process.env.GATSBY_SHOPIFY_API_ENDPOINT
  : "https://live-outer.myshopify.com/api/2023-07/graphql.json";

const DEFAULT_SHOPIFY_STORES = [
  {
    accessToken: "f39d9f2f563a1323249a770dfa7c13b7",
    uri: "https://outer-australia.myshopify.com/api/2023-07/graphql.json",
    locale: "en-AU",
  },
  // Enter other locales here

  // Last entry will be the default
  {
    accessToken: "c76701b2c91fd06e33e19a1c5c419d92",
    uri: "https://live-outer.myshopify.com/api/2023-07/graphql.json",
    locale: "en",
  },
];

const SHOPIFY_STORES = process.env.GATSBY_SHOPIFY_STORES
  ? JSON.parse(process.env.GATSBY_SHOPIFY_STORES)
  : DEFAULT_SHOPIFY_STORES;

const cache = new InMemoryCache();

const genShopifyLink = (endpoint: string, accessToken: string): ApolloLink => {
  const shopApiLink = createHttpLink({
    uri: endpoint,
    headers: {
      "X-Shopify-Storefront-Access-Token": accessToken,
    },
    fetch,
  });
  return shopApiLink;
};

interface ShopifyStore {
  accessToken: string;
  uri: string;
  locale: string;
}

const genShopifyChain = (): ApolloLink => {
  const chain: ApolloLink[] = SHOPIFY_STORES.map((store, i) => {
    if (i != SHOPIFY_STORES.length - 1) {
      return split(
        operation => operation.getContext().locale === store.locale,
        genShopifyLink(store.uri, store.accessToken)
      );
    }

    return genShopifyLink(store.uri, store.accessToken);
  });

  const shopApiRetry = new RetryLink({
    delay: {
      initial: 200,
      jitter: true,
    },
    attempts: {
      max: 5,
    },
  });

  return from([shopApiRetry, ...chain]);
};

const cmsBatch = new BatchHttpLink({
  uri: ALAMEDA_HOST,
  batchInterval: 25,
  fetch,
});

const cmsRetry = new RetryLink({
  delay: {
    initial: 200,
    jitter: true,
  },
  attempts: {
    max: 5,
  },
});

const linkCms = from([cmsRetry, cmsBatch]);

const link = split(
  operation => operation.getContext().clientName === "cms",
  linkCms,
  genShopifyChain()
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: link,
  connectToDevTools: process.env["NODE_ENV"] !== "production",
});

export default {
  client,
};
