import React, { useContext } from "react";

export interface FormsStateProps {
  step: number;
}

export interface DispatchFormsStateActionsProps {
  (action: Record<string, unknown>): void;
}

export interface FormsStateStoreProps {
  state: FormsStateProps;
  dispatch: DispatchFormsStateActionsProps;
}

const defaultState: FormsStateProps = {
  step: 0,
};

export const FormsContext = React.createContext<FormsStateStoreProps>({
  state: defaultState,
  dispatch: (a): void => {},
});

export const useFormsContext = () => useContext(FormsContext);

const formsReducer = (state = defaultState, action): FormsStateProps => {
  switch (action.type) {
    case "next":
      return { ...state, step: state.step + 1 };
    case "reset":
      return { ...state, step: 0 };

    default:
      return state;
  }
};

export const FormsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(formsReducer, defaultState);

  return (
    <FormsContext.Provider value={{ state, dispatch }}>
      {children}
    </FormsContext.Provider>
  );
};
