import * as Store from "./localDb";
import Log from "./log";
import { decodeSku } from "./helpers";

export const mergeBaseAnalyticsProps = function (props: Object): Object {
  if (typeof props != "object") {
    props = {};
  }
  const myProps = {
    branch: process.env.GATSBY_BRANCH,
    ...props,
  };
  return myProps;
};

const currentEventCount = (): [number, boolean] => {
  const lastActiveAt = Store.read("outer:lastActiveAt");
  // check if last active at was more than 30 minutes ago
  let currentEventCount = parseInt(Store.read("outer:sessionEventCount")) || 0;

  if (lastActiveAt && lastActiveAt < Date.now() - 30 * 60 * 1000) {
    currentEventCount = 0;
  } else {
    currentEventCount = currentEventCount + 1;
  }
  Store.save("outer:sessionEventCount", currentEventCount);
  Store.save("outer:lastActiveAt", Date.now());

  return [currentEventCount, !!lastActiveAt];
};

export const track = (
  name: string,
  properties: Object = {},
  options: Object = {}
) => {
  let [eventCount, repeat] = currentEventCount();
  properties = mergeBaseAnalyticsProps(properties);
  window.analytics.track(name, properties, options);

  if (window.rudderanalytics) {
    window.rudderanalytics.track(name, properties, options);
  }

  if (eventCount === 150) {
    window.analytics.track("Engaged Session Detected");
    window.rudderanalytics && window.rudderanalytics.track("Engaged Session Detected")
  } else if (eventCount === 0 && repeat) {
    window.analytics.track("Repeat Session Detected");
    window.rudderanalytics && window.rudderanalytics.track("Repeat Session Detected");
  }

  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    Log.info("TRACK", name, properties, options);
  }
};

export const identify = (
  userId: string,
  traits: Traits,
  options: Object = {}
) => {
  // HACK: dropping userID because its currently being used as an email
  // previous call was   window.analytics.identify(userId, traits, options);
  // ----HACK ABOVE RE-UPDATED for Facebook And Tiktok: userId is required for them----
  window.analytics.identify(userId, traits, options);

  if (window.rudderanalytics) {
    window.rudderanalytics.identify(userId, traits, options);
  }

  pushTraitsToDatalayer(traits);
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    Log.info("IDENTIFY", userId, traits);
  }
};

export const identifyAnon = (
  traits = { anonymousId: undefined },
  options = {}
) => {
  window.analytics.identify(traits, options);
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    Log.info("ANONYMOUS IDENTIFY", traits);
  }
};

export const page = (name = undefined, props = {}) => {
  props = mergeBaseAnalyticsProps(props);
  window.analytics.page(name, props);
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    Log.info("PAGE", name, props);
  }
};

export const trackPage = (eventName: string) => {
  const path = window.location.pathname;
  track(eventName, { path });
};

export const trackButton = (
  event: string,
  button: string,
  options: Object = {}
) => {
  return () => {
    return track(event, { button: button, ...options });
  };
};

export const persistSession = (eventName: string, currentSession: any) => {
  const secondsTimestamp = Math.floor(Date.now() / 1000);
  // clone the existing localStorage object for 'outer:session'
  const newSessionData = { ...currentSession };
  newSessionData[eventName] = secondsTimestamp;
  // set / save / replace existing outer:session obj with new one
  Store.save("outer:session", newSessionData);
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    Log.info("OUTER:SESSON UPDATED TIMESTAMPS", newSessionData);
  }
};

export const retrieveSession = () => {
  const sessionState = Store.read("outer:session");
  if (
    process.env["NODE_ENV"] !== "production" ||
    process.env["GATSBY_BRANCH"] !== "production"
  ) {
    Log.info("OUTER:SESSON RETRIEVED TIMESTAMPS", sessionState);
  }
  return sessionState;
};

export const pushTraitsToDatalayer = (traits: Traits) => {
  try {
    const traits = Store.read("ajs_user_traits");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(traits);
  } catch (err) {
    Log.error("Data layer push failed", err);
  }
};

export const genVariantTrackingObj = (
  variantParams: VariantTrackingParams
): VariantTrackingObj => {
  // Diving centsPrice by 100 since Google Analytics sees cent price as dollar value
  // e.g 100 dollars is the equivalent of 10000 cents. Instead of showing a dollar
  // value of $100, Google Analytics displays $10000
  const dollarPrice = variantParams.centsPrice / 100;
  const value = dollarPrice * variantParams.quantity;
  return {
    id: decodeSku(variantParams.b64VariantId, true),
    product_id: decodeSku(variantParams.b64ProductId, false),
    name: variantParams.productTitle,
    variant: variantParams.variantTitle,
    sku: variantParams.b64VariantId,
    price: dollarPrice,
    path: variantParams.path,
    quantity: variantParams.quantity,
    value,
  };
};

export const getUserId = (window: Window): string => {
  return window.analytics?.user?.().anonymousId() || "ID Blocked";
};
