const {
  SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
  getLocaleFromPath,
  genLocalePath,
  validateClientSidePrefixEntry,
  getRegionFromLocale,
  pageShouldBeExcluded,
  flattenStrapiObject,
} = require("./helpers");

exports.SUPPORTED_LOCALES = SUPPORTED_LOCALES;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
exports.getLocaleFromPath = getLocaleFromPath;
exports.genLocalePath = genLocalePath;
exports.validateClientSidePrefixEntry = validateClientSidePrefixEntry;
exports.getRegionFromLocale = getRegionFromLocale;
exports.pageShouldBeExcluded = pageShouldBeExcluded;
exports.flattenStrapiObject = flattenStrapiObject;
