import React, { useReducer, useEffect, useState } from "react";
import { debounce, isEmpty } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

import { getLocaleFromPath } from "../../../plugins/gatsby-plugin-outer-i18n";
import { decodeSku } from "../../utils/helpers";
import * as Analytics from "../../utils/analytics";

import { CartItem, CartState } from "../../models/Cart";
import { shopifyVariant as ProductVariantData } from "../../shopify";

import CurrentCart from "./Cart.context";
import { useUserContext } from "../User/User.context";
import {
  increaseVariant,
  decreaseVariant,
  setQuantity,
  localToShopifyCart,
  hydrateCartState,
  persistCartState,
  getItemsFromCheckout,
  hasVariant,
  findOrInitializeItem,
} from "./helpers";
import { SpecialCartActions, SPECIAL_COUPONS } from "../../../configs/cart";

import {
  ADD_CART_ITEM_MUTATION,
  ADD_DISCOUNT_CODE_MUTATION,
  CREATE_CART_MUTATION,
  GET_CHECKOUT,
  SET_EMAIL_MUTATION,
  UPDATE_CART_ITEMS_MUTATION,
} from "./Cart.queries";

import { CartReducer } from "./Cart.reducer";

const initialState: CartState = Object.assign(
  {},
  {
    attached: false,
    show: false,
    loading: true,
    synced: false,
    checkoutId: "",
    errors: [],
    checkout: {
      subtotalPrice: { amount: 0 },
      lineItemsSubtotalPrice: { amount: 0 },
    },
    promoCode: "",
    cartModalVisible: false,
    discountSelected: "",
    clydeLoading: true,
    purchaseTrackerItems: [],
  },
  hydrateCartState()
);

export const CartProvider = ({ children }) => {
  const [updateCartMutation] = useMutation(UPDATE_CART_ITEMS_MUTATION);
  const [addCartItemMutation] = useMutation(ADD_CART_ITEM_MUTATION);
  const [promoCodeMutation] = useMutation(ADD_DISCOUNT_CODE_MUTATION);
  const [createCart] = useMutation(CREATE_CART_MUTATION);

  const {
    state: { user, loading: userLoading },
  } = useUserContext();

  const [state, dispatch] = useReducer(CartReducer, initialState);

  if (state.synced) {
    persistCartState(state);
  }

  const mutateCart = async (
    mutateFn: any,
    options: any
  ): Promise<any | null> => {
    try {
      let results = await mutateFn(options);
      return results;
    } catch (e) {
      console.error(e);
      createNewCart({ input: {} });
      return null;
    }
  };

  const updateProductInCart = debounce(async items => {
    let results = (await mutateCart(updateCartMutation, {
      context: {
        locale: user.locale.code,
      },
      variables: {
        checkoutId: state.checkoutId,
        lineItems: localToShopifyCart(items),
      },
    })) as any;
    if (results) {
      dispatch({
        type: "RECEIVED_CHECKOUT",
        payload: results.data.checkoutLineItemsReplace.checkout,
      });
    }
  }, 500);

  const addItemWithQuantity = async (
    variant: ProductVariantData,
    quantity: number = 1
  ) => {
    dispatch({ type: "UPDATING_CART", payload: {} });
    let items = setQuantity(
      getItemsFromCheckout(state.checkout),
      variant,
      quantity
    );

    return await updateProductInCart(items);
  };

  const addItem = async (variant: ProductVariantData) => {
    dispatch({ type: "UPDATING_CART", payload: {} });
    let items = increaseVariant(getItemsFromCheckout(state.checkout), variant);
    return await updateProductInCart(items);
  };

  const addItems = async (variants: ProductVariantData[]) => {
    dispatch({ type: "UPDATING_CART", payload: {} });

    let items: CartItem[] = variants.reduce((items, variant) => {
      return setQuantity(items, variant, variant.quantity || 1);
    }, getItemsFromCheckout(state.checkout));

    return await updateProductInCart(items);
  };

  const removeItem = async (variant: ProductVariantData) => {
    dispatch({ type: "UPDATING_CART", payload: {} });
    let items = decreaseVariant(getItemsFromCheckout(state.checkout), variant);

    return await updateProductInCart(items);
  };

  const removeCompleteItems = async (variants: ProductVariantData[]) => {
    dispatch({ type: "UPDATING_CART", payload: {} });

    let items = variants.reduce((acc, variant) => {
      const item = findOrInitializeItem(acc, variant);

      if (
        (variant.product.title === "Clyde Protection Plan" ||
          variant.product.title === "XCover Protection Plan") &&
        variants.length !== 1
      ) {
        const productObj = variants.filter(v => v.id !== variant.id)[0];
        const product = findOrInitializeItem(
          getItemsFromCheckout(state.checkout),
          productObj
        );
        if (item.quantity !== product.quantity) {
          return decreaseVariant(acc, item.variant, product, true);
        }
      }

      return acc.filter(i => i.variant.id != variant.id);
    }, getItemsFromCheckout(state.checkout));

    return await updateProductInCart(items);
  };

  const toggle = () => {
    if (state.attached === true) {
      dispatch({ type: "HIDE_CART", payload: {} });
      setTimeout(() => {
        dispatch({ type: "DETACH_CART", payload: {} });
      }, 500);
    } else {
      Analytics.track("Cart Viewed");
      dispatch({ type: "ATTACH_CART", payload: {} });
      setTimeout(() => {
        dispatch({ type: "SHOW_CART", payload: {} });
      }, 100);
    }
  };

  const applyPromo = async (code: string) => {
    code = code.toUpperCase();
    let items: CartItem[] = getItemsFromCheckout(state.checkout);
    dispatch({ type: "UPDATING_CART", payload: {} });

    Analytics.track("Coupon Entered", { coupon_id: code });
    for (let i = 0; i < SPECIAL_COUPONS.length; i++) {
      const coupon = SPECIAL_COUPONS[i];
      const myCode = coupon.code.toUpperCase();
      if (code === myCode && !hasVariant(items, coupon.variantId)) {
        // Ive omitted properties that are not needed for this to work. The bank strings and empty photos are for type checking to work
        items = increaseVariant(items, {
          id: coupon.variantId,
          title: coupon.title,
          image: "",
          product: {
            id: "",
            title: coupon.title,
            slug: "",
            price: 0,
            remoteId: "",
            outOfStock: false,
            photos: [],
          },
          price: 0,
        });
      }
    }

    let specialAction = SpecialCartActions[code];
    if (specialAction) {
      items = specialAction(items);
    }

    if (items !== getItemsFromCheckout(state.checkout)) {
      await updateCartMutation({
        context: {
          locale: user.locale.code,
        },
        variables: {
          checkoutId: state.checkoutId,
          lineItems: localToShopifyCart(items),
        },
      });
    }

    const results = (await promoCodeMutation({
      context: {
        locale: user.locale.code,
      },
      variables: { discountCode: code, checkoutId: state.checkoutId },
    })) as any;

    const {
      data: {
        checkoutDiscountCodeApplyV2: { checkout, checkoutUserErrors },
      },
    } = results;

    const isCodeAppliedInShopify = checkout?.discountApplications?.edges.some(
      edge => edge.node.code?.toUpperCase() === code
    );

    if (checkoutUserErrors.length === 0 && isCodeAppliedInShopify) {
      dispatch({
        type: "APPLIED_PROMO",
        payload: { code, errors: checkoutUserErrors },
      });

      Analytics.track("Coupon Applied", { coupon_id: code });
      setLoadingPromo(false);
    } else {
      dispatch({
        type: "PROMO_ERROR",
        payload: {
          errors:
            checkoutUserErrors.length > 0
              ? checkoutUserErrors
              : [
                  {
                    code: "DISCOUNT_NOT_FOUND",
                  },
                ],
        },
      });
      Analytics.track("Coupon Denied", { coupon_id: code });
      setLoadingPromo(false);
    }

    dispatch({ type: "RECEIVED_CHECKOUT", payload: checkout });
  };

  const retryPromo = async () => {
    dispatch({ type: "RETRY_PROMO", payload: {} });
  };

  const onCheckout = async () => {
    const items = getItemsFromCheckout(state.checkout);
    const products = items.map(el => {
      return {
        id: decodeSku(el.variant.id, true),
        sku: el.variant.id,
        name: el.variant?.product!.title,
        variant: el.variant.title,
        // @ts-ignore
        price: el.variant.price.amount,
        quantity: el.quantity,
      };
    });
    state.promoCode.length > 1
      ? Analytics.track("Initiate Checkout", {
          promoCode: state.promoCode,
          products,
        })
      : Analytics.track("Initiate Checkout", { products });
    Analytics.track("Checkout Started");
    dispatch({ type: "CHECKING_OUT", payload: {} });
    let url = state.checkout.webUrl;
    url = url.replace("live-outer.myshopify.com", "shop.liveouter.com");
    window.location = url;
  };

  const createNewCart = async (variables: any) => {
    createCart({
      context: {
        locale: user.locale.code,
      },
      variables,
    }).then((results: any) => {
      const {
        data: {
          checkoutCreate: { checkout },
        },
      } = results;
      dispatch({ type: "RECEIVED_CHECKOUT", payload: checkout });
    });
  };

  const savePromo = async promoCode => {
    dispatch({ type: "SAVE_PROMO", payload: { promoCode } });
  };

  const setCartModalVisible = async cartModalVisible => {
    dispatch({
      type: "SET_CART_MODAL_VISIBLE",
      payload: { cartModalVisible },
    });
  };

  const setDiscountSelected = async discountSelected => {
    dispatch({
      type: "SET_DISCOUNT_SELECTED",
      payload: { discountSelected },
    });
  };

  const setCartLoading = async loading => {
    dispatch({
      type: "SET_CART_LOADING",
      payload: { loading },
    });
  };

  const setClydeLoading = async clydeLoading => {
    dispatch({
      type: "SET_CLYDE_LOADING",
      payload: { clydeLoading },
    });
  };

  // TRACKER LOGIC

  const setInitPurchaseTrackerItems = async () => {
    await dispatch({
      type: "SET_PURCHASE_TRACKER_ITEMS",
      payload: undefined,
    });
  };

  const setPurchaseTrackerItems = async item => {
    await dispatch({
      type: "SET_PURCHASE_TRACKER_ITEMS",
      payload: item,
    });
  };

  // Queries
  let { data, loading, error, refetch } = useQuery(GET_CHECKOUT, {
    context: {
      locale: user.locale.code,
    },
    variables: { id: state.checkoutId || null },
    skip: userLoading,
  });

  // Effect to set the cart as unsynced when the user switches windows and sync the cart when they return.

  useEffect(() => {
    const refetchQuery = () => refetch();
    const setCartSync = () =>
      dispatch({ type: "SET_SYNCED", payload: { synced: false } });
    window.addEventListener("focus", refetchQuery);
    window.addEventListener("blur", setCartSync);
    return () => {
      window.removeEventListener("focus", refetchQuery);
      window.removeEventListener("blur", setCartSync);
    };
  });

  // Function that adds a single item to the cart without checking existing products

  const addCartItems = async (items: ProductVariantData[]) => {
    dispatch({ type: "UPDATING_CART", payload: {} });
    const formattedItems = items.map(item => ({
      quantity: item.quantity,
      variantId: item.id,
      ...(item.customAttributes && {
        customAttributes: item.customAttributes.map(attr => ({
          key: attr.key,
          value: attr.value,
        })),
      }),
    }));

    let results = (await mutateCart(addCartItemMutation, {
      context: {
        locale: user.locale.code,
      },
      variables: {
        checkoutId: state.checkoutId,
        lineItems: formattedItems,
      },
    })) as any;
    if (results) {
      dispatch({
        type: "RECEIVED_CHECKOUT",
        payload: results.data.checkoutLineItemsAdd.checkout,
      });

      items.forEach(item => {
        Analytics.track("Product Added", {
          id: decodeSku(item.id, true),
          product_id: decodeSku(item.id, true),
          name: item.product.title,
          variant: item.title,
          sku: item.id,
          price: item.price,
          quantity: item.quantity,
        });
      });
    }
  };

  useEffect(() => {
    if (userLoading) return;

    const pathLocale = getLocaleFromPath(window.location.pathname);

    if (isEmpty(state.checkoutId) || pathLocale !== user.locale.code) {
      createNewCart({ input: {} });
      return;
    }
    if (state.synced === false && !loading && data) {
      if (!data.node || data.node.completedAt) {
        createNewCart({ input: {} });
      } else {
        dispatch({ type: "RECEIVED_CHECKOUT", payload: data.node });
      }
    }
  }, [data, state.checkoutId, userLoading]);

  /**
   * ***********************************
   * URL PROMO LOGIC
   * ***********************************
   */

  const [loadingPromo, setLoadingPromo] = useState(false);

  useEffect(() => {
    let myPromo = "";
    if (typeof window.URLSearchParams != "undefined") {
      const query = new window.URLSearchParams(window.location.search);
      myPromo = query.get("promo") || "";
      myPromo = myPromo.toUpperCase(); // Promos should always be uppercase
    }

    if (myPromo != "" && state.checkoutId) {
      Analytics.track("Promo Code Visit", { promo: myPromo });
      dispatch({ type: "SAVE_URL_PROMO", payload: { urlPromoCode: myPromo } });
    }
  }, [state.checkoutId]);

  useEffect(() => {
    const items = getItemsFromCheckout(state.checkout);

    if (
      !loadingPromo &&
      items.length &&
      state.urlPromoCode &&
      state.urlPromoCode != "" &&
      !state.loading &&
      state.promoCode !== state.urlPromoCode
    ) {
      applyPromo(state.urlPromoCode);
      setLoadingPromo(true);
    }

    const areValidProducts = items.every(item => item.customAttributes.length);
    if (!areValidProducts) {
      createNewCart({ input: {} });
    }
  }, [state.urlPromoCode, state.checkout.lineItems]);

  /**
   * ***********************************
   * PROVIDER ACTIONS
   * ***********************************
   */

  const actions = {
    addItem,
    addItems,
    removeItem,
    removeCompleteItems,
    toggle,
    onCheckout,
    applyPromo,
    retryPromo,
    createNewCart,
    addItemWithQuantity,
    savePromo,
    setCartModalVisible,
    setDiscountSelected,
    setCartLoading,
    setClydeLoading,
    addCartItems,
    setPurchaseTrackerItems,
    setInitPurchaseTrackerItems,
  };

  return (
    <CurrentCart.Provider value={{ state, actions }}>
      {children}
    </CurrentCart.Provider>
  );
};
