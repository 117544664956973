export const showroomsSearchConfig = {
  searchFallbackRadius: 60,
  distanceOptions: {
    5: "Within 5 miles",
    10: "Within 10 miles",
    15: "Within 15 miles",
    20: "Within 20 miles",
    25: "Within 25 miles",
    60: "Within 30+ miles",
  },
  defaultLocations: {
    us: {
      name: "Venice Beach, Los Angeles, CA, USA",
      latitude: 33.9850469,
      longitude: -118.46948320000001,
    },
    au: {
      name: "Sydney NSW, Australia",
      latitude: -33.8688197,
      longitude: 151.2092955,
    },
  },
};
