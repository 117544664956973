// Naming exceptions dictionary
export const namingExceptions = {
  wickerOnly: "Wicker",
  wicker: "Brown Wicker",
  wickerWhite: "White Wicker",
  wickerBlack: "Black Wicker",
  aluminumWhite: "White Aluminum",
  coffeeTable: "Coffee Table",
  diningTable: "Dining Table",
  firePitTable: "Fire Pit Table",
  outerStone: "OuterStone™",
  outerStoneAndAluminum: "OuterStone™ & Aluminum",
};
