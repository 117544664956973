import React from "react";
import { isEmpty, isNil, omit, sortBy } from "lodash";

import { RESET_CART_BEFORE } from "../../../configs/cart";
import { transformShopifyResults, decodeSku } from "../../utils/helpers";
import * as Store from "../../utils/localDb";
import * as Analytics from "../../utils/analytics";
import Log from "../../utils/log";

import { CartItem, CartState } from "../../models/Cart";
import { ProductVariantData } from "../../models/Product";

export const findOrInitializeItem = (
  items: CartItem[],
  variant: ProductVariantData
): CartItem => {
  const itemExist = items.find(item => item.variant?.id === variant?.id);

  return (
    itemExist || {
      variant: variant,
      quantity: 0,
      discountAllocations: [],
      customAttributes: [],
    }
  );
};

const transformCustomAttributes = ({
  customAttributes,
  reference,
  operation,
  quantity,
  variant,
}: {
  customAttributes: { key: string; value: string }[];
  reference?: string;
  operation: string;
  quantity?: number;
  variant?: ProductVariantData;
}) => {
  const variantRef = variant?.customAttributes.find(attr =>
    attr.key.includes("ref-")
  );
  return customAttributes.reduce((acc, el, index, array) => {
    // if reference exists
    if (
      el.key.includes(variantRef?.key) ||
      (reference && el.key.includes(reference))
    ) {
      if (operation === "remove") {
        const existingQuantity = Number(el.value) - (quantity || 1);
        if (existingQuantity === 0) {
          return acc;
        }
        const obj = {
          key: el.key,
          value: `${Number(el.value) - 1}`,
        };
        acc.push(obj);
        return acc;
      }
      if (operation === "add") {
        const obj = {
          key: el.key,
          value: quantity ? `${quantity}` : `${Number(el.value) + 1}`,
        };
        acc.push(obj);
        return acc;
      }
    }
    if (array.length - 1 === index && variantRef) {
      // if reference not exists
      acc.push(el, {
        key: variantRef.key,
        value: quantity ? `${quantity}` : `1`,
      });
      return acc;
    }

    acc.push(el);
    return acc;
  }, [] as { key: string; value: string }[]);
};

export const productWarranty = (items, variant) =>
  items
    .filter(
      item =>
        item.variant.product.title === "Clyde Protection Plan" ||
        item.variant.product.title === "XCover Protection Plan"
    )
    .find(w =>
      w.customAttributes?.some(attr =>
        attr.key.includes(`ref-${decodeSku(variant.id, true)}`)
      )
    );

export const increaseVariant = (
  items: CartItem[],
  variant: ProductVariantData,
  productWithWarranty?: ProductVariantData
): CartItem[] => {
  let item = findOrInitializeItem(items, variant);
  const itemCopy = { ...item };
  itemCopy.quantity = item.quantity + 1;
  items = items.filter(i => i.variant.id != variant.id);
  const warranty = productWarranty(items, variant);

  if (warranty) {
    items = increaseVariant(items, warranty.variant, itemCopy);
  }

  if (
    variant.product.title === "Clyde Protection Plan" ||
    variant.product.title === "XCover Protection Plan"
  ) {
    const currentVariantReference = decodeSku(
      productWithWarranty?.variant?.id,
      true
    );

    const newCustomAttributesArray = transformCustomAttributes({
      customAttributes: itemCopy.customAttributes,
      reference: currentVariantReference,
      operation: "add",
    });

    itemCopy.customAttributes = newCustomAttributesArray;
  }

  items.push(itemCopy);
  const variantObjParam = {
    b64VariantId: itemCopy.variant.id,
    b64ProductId: itemCopy.variant.product.id,
    variantTitle: variant.title,
    productTitle: variant.product.title,
    centsPrice: itemCopy.variant.price.amount * 100,
    quantity: itemCopy.quantity,
    path: window.location.pathname,
  };
  Analytics.track(
    "Product Added",
    Analytics.genVariantTrackingObj(variantObjParam)
  );
  return items;
};

export const decreaseVariant = (
  items: CartItem[],
  variant: ProductVariantData,
  productWithWarranty?: ProductVariantData,
  decreaseAll?: boolean
): CartItem[] => {
  let item = findOrInitializeItem(items, variant);
  const itemCopy = { ...item };
  itemCopy.quantity = item.quantity - 1;
  items = items.filter(i => i.variant.id != variant.id);
  const warranty = productWarranty(items, variant);

  if (warranty) {
    items = decreaseVariant(items, warranty.variant, itemCopy);
  }

  if (
    variant.product.title === "Clyde Protection Plan" ||
    variant.product.title === "XCover Protection Plan"
  ) {
    const currentVariantReference = decodeSku(
      productWithWarranty?.variant?.id,
      true
    );

    const newCustomAttributesArray = transformCustomAttributes({
      customAttributes: itemCopy.customAttributes,
      reference: currentVariantReference,
      operation: "remove",
      ...(decreaseAll && { quantity: productWithWarranty.quantity }),
    });

    itemCopy.customAttributes = newCustomAttributesArray;

    if (decreaseAll) {
      itemCopy.quantity = item.quantity - productWithWarranty.quantity;
    }
  }

  if (itemCopy.quantity != 0) {
    items.push(itemCopy);
  }

  Analytics.track("Product Removed", {
    id: decodeSku(itemCopy.variant.id, true),
    product_id: decodeSku(itemCopy.variant.id, true),
    name: itemCopy.variant.product.title,
    variant: itemCopy.variant.title,
    sku: itemCopy.variant.id,
    price: itemCopy.variant.price,
    quantity: itemCopy.quantity,
  });
  return items;
};

export const setQuantity = (
  items: CartItem[],
  variant: ProductVariantData,
  quantity: number,
  operation: string = "add"
): CartItem[] => {
  let item = findOrInitializeItem(items, variant);
  const itemCopy = { ...item };

  const warranty = productWarranty(items, variant);

  items = items.reduce((acc, el) => {
    if (el.variant.id === variant.id) {
      return acc;
    }
    if (el.variant.id === warranty?.variant.id) {
      const warrantyQuantity = el.quantity - itemCopy.quantity;
      if (warrantyQuantity === 0) {
        return acc;
      } else {
        const newWarranty = {
          ...el,
          quantity: el.quantity - itemCopy.quantity,
          customAttributes: el.customAttributes.filter(
            attr =>
              !attr.key.includes(`ref-${decodeSku(itemCopy.variant.id, true)}`)
          ),
        };
        acc.push(newWarranty);
        return acc;
      }
    }

    acc.push(el);
    return acc;
  }, [] as CartItem[]);

  // If product is a warranty check if already exists
  if (itemCopy && variant.type === "warranty") {
    if (itemCopy.customAttributes && itemCopy.customAttributes.length) {
      const newCustomAttributesArray = transformCustomAttributes({
        customAttributes: itemCopy.customAttributes,
        variant: variant,
        operation: operation,
        quantity: quantity,
      });

      itemCopy.customAttributes = newCustomAttributesArray;
    } else {
      itemCopy.customAttributes = variant.customAttributes;
    }

    const warrantyQuantity = itemCopy.customAttributes.reduce((acc, attr) => {
      if (attr.key.includes("ref-")) {
        return acc + Number(attr.value);
      }
      return acc;
    }, 0);
    itemCopy.quantity = warrantyQuantity;
  } else {
    itemCopy.quantity = quantity;
  }

  if (
    variant.customAttributes?.length > 0 &&
    itemCopy.customAttributes?.length === 0
  ) {
    itemCopy.customAttributes = variant.customAttributes;
  }

  items.push(itemCopy);
  return items;
};

export const isValidCart = (cart: CartState): boolean => {
  if (isNil(cart)) {
    return false;
  }

  // Allows for us to "flush" old carts that could cause issues
  if (cart.timestamp == undefined || RESET_CART_BEFORE >= cart.timestamp) {
    return false;
  }

  if (isNil(cart.checkoutId) || isEmpty(cart.checkoutId)) {
    return false;
  }

  if (
    isNil(cart.checkout) ||
    isNil(cart.checkout.id) ||
    isEmpty(cart.checkout.id)
  ) {
    return false;
  }

  return true;
};

export const localToShopifyCart = (
  items: CartItem[]
): {
  quantity: number;
  variantId: string;
  attributes?: any;
}[] => {
  return items.map(i => ({
    quantity: i.quantity,
    variantId: i.variant.id,
    ...(i.customAttributes && {
      customAttributes: i.customAttributes.map(attr => ({
        key: attr.key,
        value: attr.value,
      })),
    }),
  }));
};

export const hydrateCartState = (): CartState => {
  let myCart: CartState;
  if (typeof window === "undefined") return;
  try {
    if (typeof localStorage === "undefined") {
      Log.info("Local storage is not ready");
      return;
    }

    myCart = JSON.parse(localStorage.getItem("cart") || "{}") as CartState;
    if (!isValidCart(myCart)) {
      Log.info("Invalid cart re-initializing", myCart);
      Log.info("re-initializing cart");
      myCart = {} as CartState;
      persistCartState(myCart);
    }
  } catch (err) {
    Log.error(err);
    Log.info("re-initializing cart");
    myCart = {} as CartState;
    persistCartState(myCart);
  }
  return myCart as CartState;
};

export const persistCartState = (cart: CartState) => {
  let state: any = omit(cart, ["synced", "errors"]);
  let persistedCart = Object.assign({}, state);
  persistedCart.timestamp = Math.round(new Date().getTime() / 1000);
  Store.save("cart", persistedCart);
};

export const sortItems = (items: CartItem[]): CartItem[] => {
  return sortBy(items, i => {
    if (i.customAttributes && i.customAttributes.length) {
      return i.customAttributes[0].key;
    }

    let title = "";
    if (i.variant && i.variant.product) {
      title = `${i.variant.product.title} - ${i.variant.title}`;
    } else if (i.variant) {
      title = i.variant.title;
    }
    return title;
  });
};

export const getItemsFromCheckout = (checkout: any): CartItem[] => {
  try {
    return transformShopifyResults(checkout.lineItems) || [];
  } catch {
    return [];
  }
};

export const hasVariant = (items: CartItem[], variantId: string): boolean => {
  if (items.find(i => i.variant.id === variantId)) {
    return true;
  } else {
    return false;
  }
};

export const getPromoCodesFromItems = (items: CartItem[]): string[] => {
  const allDiscounts = items
    .map((item: CartItem) => item.discountAllocations)
    .reduce((acc, discountAllocation) => acc.concat(discountAllocation), []);

  const codesApplied = [
    ...new Set(
      allDiscounts
        .filter(discount => discount.discountApplication.code)
        .map(discount => discount.discountApplication.code)
    ),
  ];

  return codesApplied;
};

export const encodeCustomAttributes = customAttributes =>
  Object.keys(customAttributes).reduce((acc, attr) => {
    // @ts-ignore
    acc.push({ key: attr, value: `${customAttributes[attr]}` });
    return acc;
  }, []);

export const decodeCustomAttributes = customAttributes =>
  customAttributes.reduce((acc, attr) => {
    return {
      ...acc,
      [attr["key"]]: attr["value"],
    };
  }, {});
