import { CartState } from "../../models/Cart";

export const CartReducer = (
  state: CartState,
  action: { type: string; payload: any }
): CartState => {
  try {
    switch (action.type) {
      case "HYDRATED_STATE":
        return { ...state, ...action.payload };
      case "UPDATING_CART":
        return { ...state, loading: true };
      case "RECEIVED_CHECKOUT":
        let checkout = action.payload;
        return {
          ...state,
          loading: false,
          synced: true,
          checkoutId: checkout.id,
          checkout,
        };
      case "CHECKING_OUT":
        return { ...state, loading: true };
      case "HIDE_CART":
        return { ...state, show: false };
      case "SHOW_CART":
        return { ...state, show: true };
      case "DETACH_CART":
        return { ...state, attached: false };
      case "ATTACH_CART":
        return { ...state, attached: true };
      case "APPLIED_PROMO":
        return {
          ...state,
          promoCode: action.payload.code,
          urlPromoCode: "",
          discountSelected: "",
          errors: action.payload.errors,
        };
      case "PROMO_ERROR":
        return {
          ...state,
          errors: action.payload.errors,
          promoCode: "",
          urlPromoCode: "",
        };
      case "RETRY_PROMO":
        return { ...state, errors: [] };
      case "SAVE_PROMO":
        return { ...state, promoCode: action.payload.promoCode };
      case "SAVE_URL_PROMO":
        return { ...state, urlPromoCode: action.payload.urlPromoCode };
      case "REMOVE_URL_PROMO":
        const newState = { ...state };
        newState.urlPromoCode = undefined;
        return newState;
      case "SET_CART_MODAL_VISIBLE":
        return {
          ...state,
          cartModalVisible: action.payload.cartModalVisible,
        };
      case "SET_DISCOUNT_SELECTED":
        return {
          ...state,
          discountSelected: action.payload.discountSelected,
        };
      case "SET_CART_LOADING":
        return {
          ...state,
          loading: action.payload.loading,
        };
      case "SET_CLYDE_LOADING":
        return {
          ...state,
          clydeLoading: action.payload.clydeLoading,
        };
      case "SET_SYNCED":
        return {
          ...state,
          synced: action.payload.synced,
        };
      case "SET_PURCHASE_TRACKER_ITEMS":
        let newItems: any = [];

        if (action.payload) {
          newItems = [
            ...(state.purchaseTrackerItems.filter(
              item => item.remoteId !== action.payload.remoteId
            ) || []),
            ...(action.payload.quantity > 0 ? [action.payload] : []),
          ];
        }

        return {
          ...state,
          purchaseTrackerItems: newItems,
        };
      default:
        return state;
    }
  } catch {
    return state;
  }
};
