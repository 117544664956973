export const stateHash = {
  ACT: "Australian Capital Territory",
  NSW: "New South Wales",
  NT: "Northern Territory",
  QLD: "Queensland",
  SA: "South Australia",
  TAS: "Tasmania",
  VIC: "Victoria",
  WA: "Western Australia",
};

export const Australia = {
  abbreviations: Object.keys(stateHash),
  states: Object.values(stateHash),
  fromAbbreviation: abbr => {
    return stateHash[abbr.toUpperCase()];
  },
  fromFullName: state => {
    return Object.keys(stateHash).find(
      k => stateHash[k] === state || stateHash[k].toLowerCase() === state
    );
  },
};

export const phoneInternationalCode = "61";

export const ausRegionalCopy = {
  phoneNumberValidationRules: {
    required: true,
    pattern: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[ ]*[-s./0-9]{9,}$",
    min: 10,
    message: "Please enter a valid ten digit AUS phone number",
    validateTrigger: "onSubmit",
  },
  distanceUnit: "km",
  displayDistance: "km",
  stateProvinceText: "State/territory",
  cityText: "Suburb",
  zipCodeText: "Postcode",
  supportEmail: "ordersau@liveouter.com",
  ordersEmail: "ordersau@liveouter,com",
  teamEmail: "au@liveouter.com",
  contactNumber: `+${phoneInternationalCode}249349351`,
  variantLabels: {
    byColor: "Colour - ",
    bySize: "Size - ",
    byDollarAmount: "Amount - ",
    byOption: "",
  },
};

// key should be {componentName}--{descriptor} - this is so in the future
// should this be moved out into JSON or individual files/etc this can still be managed
export const ausTextTranslations = {
  "ModalStep--good-fit-input":
    "Tell us why you and your outdoor space are a good fit for our Neighbourhood Showroom program (optional).",
  "ModalStep--custom-button-text": "Shop Settings",
  "DetailPageSidebar--description":
    "Outer’s Neighbourhood Showrooms are located at the homes of real Outer customers in your area.",
  "NoResults--cta-text":
    "Interested in hosting a Neighbourhood Showroom? Apply today and earn 10% off of your first purchase.",
  "ShowroomCard--tooltip-condition": "Neighbourhood Showroom",
  "ShowroomDetails--other-showroom-header": "Other Neighbourhood Showrooms",
  "ShowroomDetails--tooltip-condition": "Neighbourhood Showroom",
  "ActionSection--tooltip-condition": "Neighbourhood Showroom",
  "InfoSection--info-text":
    "Outer’s Neighbourhood Showrooms are located at the homes of real Outer customers in your area. For privacy, the exact location will be provided to you by an Outer Concierge after you submit your request to connect.",
  "Covid19--nbs-header": "Neighbourhood Showroom",
  "Covid19--li-one-text":
    "For the time being, we have suspended in-person showroom visits to our Neighbourhood Showrooms to ensure the safety of our community.",
  "Covid19--li-three-text":
    "We're actively accepting applications for Neighbourhood Showroom Hosts! Apply today to host a virtual showroom and receive 10% off the purchase of your outdoor sofa.",
  "About--values-description":
    "Our customers, our team, our families, our friends and our neighbours. These people are at the center of everything that we do. We made Outer for all of us, because life is meant to be shared.",
  "About--approach-nbs-subtitle": "The Neighbourhood Showroom",
  "About--community-description":
    "We aim to connect people from all neighbourhoods and all walks of life. We’re a collection of familiar and unfamiliar faces, bound together by our values and a sense of pride around makes each of us unique.",
  "QuizData--frame-style-options": [
    "Teak Frame Style",
    "Wicker Frame Style",
    "Aluminium Frame Style",
  ],
  "QuizData--frame-style-slugs": [
    "teak-outdoor-sofa-three-seat",
    "the-three-piece",
    "aluminium-outdoor-sofa-three-seat",
  ],
  "QuizData--fabric-preference-title": "What colour fabric do you prefer?",
  "QuizData--fabric-preference-options": [
    "Pacific Fog Grey",
    "Palisades Cream",
  ],
  "QuizWizard--getQuizStepImages-color": "Pacific Fog Grey",
  "ConfigurationQuiz--frame-options": ["teak", "wicker", "aluminium"],
  "ConfigurationQuiz--color-options": ["Pacific Fog Grey", "Palisades Cream"],
  "Filters--location-autocomplete-placeholder":
    "Search by suburb, address or postcode",
  "NewsletterForm--zipcode-input-placeholder": "Enter Postcode...",
  "ImagePanSlide--sections-01-content":
    "Our custom performance fabric is stain, fade and mould resistant yet still soft to the touch.",
  "CustomProductsCard--preview-card-color": "Colour",
  "CartItems--variant-type-text-by-color": "Colour",
  "VariantSelector--choose-fabric-p": "Choose your fabric colour:",
  "ProductDimensions--variant-type-color": "colour",
  "Explore1188EcoFriendlyOutdoorRugs--rugprops-subtitle":
    "Three colours and sizes to choose from",
  "Filters--aluminum": "aluminium",
  "Filters--White Aluminum": "White Aluminium",
  "Filters--Charcoal Aluminum": "Charcoal Aluminium",
  "Filters--Color": "Colour",
  "ColorSelector--header-text": "Cushion Fabric Colour",
  "ColorSelector--header-standard-text": "Colour",
};

export const ausAddlConfig = {
  klayvioPrefix: "listau",
  bnplProvider: "openpay",
};
