import { useState, useEffect } from "react";
import { useUserContext } from "../contexts/User/User.context";
import {
  countriesMap,
  countriesCopyMap,
  countriesTranslationsMap,
  countriesAddlConfig,
} from "../utils/regions/countries";

function useLocalization() {
  const [locale, setLocale] = useState({ code: "en", region: "us" });
  const [territories, setTerritories] = useState(countriesMap.us);
  const [regionalCopy, setRegionalCopy] = useState(countriesCopyMap.us);
  const [translations, setTranslation] = useState(countriesTranslationsMap.us);
  const [addlConfig, setAddlConfig] = useState(countriesAddlConfig.us);

  const {
    state: { user, loading },
  } = useUserContext();

  useEffect(() => {
    const currentLocale = {
      code: user.locale.code,
      region: user.locale.region,
    };
    const currentCountryMap = countriesMap[user.locale.region || "us"];
    const currentCountryCopyMap = countriesCopyMap[user.locale.region || "us"];
    const currentCountryTranslationsMap =
      countriesTranslationsMap[user.locale.region || "us"];
    const currentCountryAddlConfig =
      countriesAddlConfig[user.locale.region || "us"];

    setLocale(currentLocale);
    setTerritories(currentCountryMap);
    setRegionalCopy(currentCountryCopyMap);
    setTranslation(currentCountryTranslationsMap);
    setAddlConfig(currentCountryAddlConfig);
  }, [loading, user]);

  return { translations, regionalCopy, territories, addlConfig, locale };
}

export default useLocalization;
