const _ = require("lodash");

const SUPPORTED_LOCALES = ["en", "en-AU"]; // Default is always the 0 index
exports.SUPPORTED_LOCALES = SUPPORTED_LOCALES;

const DEFAULT_LOCALE = SUPPORTED_LOCALES[0];
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;

const removeTrailingSlash = path =>
  path === `/` ? path : path.replace(/\/$/, ``);

const getLocaleFromPath = path => {
  const paths = path.split("/");
  for (let i = 0; i < SUPPORTED_LOCALES.length; i++) {
    const locale = SUPPORTED_LOCALES[i];

    // example path '/en-AU/teak'
    // paths Array(3): [""],["en-AU"],["teak"]
    // index at 0 is empty, index at 1 has en-AU

    const pathIsLocale = path => path.toLowerCase() === locale.toLowerCase();

    if (paths.findIndex(pathIsLocale) === 1) {
      return locale;
    }
  }
  return DEFAULT_LOCALE;
};

exports.getLocaleFromPath = getLocaleFromPath;

const removeLocaleFromPath = path => {
  const paths = path.split("/");
  for (let i = 0; i < SUPPORTED_LOCALES.length; i++) {
    const locale = SUPPORTED_LOCALES[i];

    const pathIsLocale = path => path.toLowerCase() === locale.toLowerCase();

    if (paths.findIndex(pathIsLocale) === 1) {
      paths.splice(1, 1);
      return paths.join("/");
    }
  }
  return path;
};

exports.removeLocaleFromPath = removeLocaleFromPath;

const genLocalePath = (locale, slug) => {
  var path = slug;
  // Default to root path
  if (_.isNull(path) || path === "") {
    path = "/";
  }

  if (_.isNull(locale)) {
    console.log("Null locale for", slug);
    locale = "en";
  }

  // Prepend slash
  if (path[0] !== "/") {
    path = "/" + path;
  }

  // For non en-US remove nest the nest the page
  if (locale !== "en") {
    path = `/${locale}${path}`;
  }
  return removeTrailingSlash(path);
};

exports.genLocalePath = genLocalePath;

// client sides paths should be globs (i.e. of the form "/*" or "/foo/*")
const validateClientSidePrefixEntry = prefix => {
  if (!prefix.match(/^\//) || !prefix.match(/\/\*$/)) {
    throw Error(
      `Plugin "gatsby-plugin-outer-i18n" found invalid clientSidePrefix pattern: ${prefix}`
    );
  }
};

exports.validateClientSidePrefixEntry = validateClientSidePrefixEntry;

const getRegionFromLocale = locale => {
  const intl =
    typeof Intl.Locale === "function" ? new Intl.Locale(locale) : null;
  return intl && intl.region ? intl.region.toLowerCase() : "us";
};

exports.getRegionFromLocale = getRegionFromLocale;

// exclusion map should be a double look up
const pageShouldBeExcluded = (exclusionMap, locale, componentName) => {
  if (
    exclusionMap &&
    exclusionMap.hasOwnProperty(locale) &&
    exclusionMap[locale].hasOwnProperty(componentName)
  ) {
    console.log(`/${locale}/${componentName} excluded from building`);
    return true;
  }
  return false;
};

exports.pageShouldBeExcluded = pageShouldBeExcluded;

const flattenStrapiObject = obj => {
  if (typeof obj === "object" && obj !== null) {
    if (obj.hasOwnProperty("data")) {
      return flattenStrapiObject(obj.data);
    }

    if (obj.hasOwnProperty("attributes")) {
      return flattenStrapiObject(obj.attributes);
    }

    if (Array.isArray(obj)) {
      return obj.map(item => flattenStrapiObject(item));
    }

    const result = {};
    for (const [key, value] of Object.entries(obj)) {
      result[key] = flattenStrapiObject(value);
    }
    return result;
  }
  return obj;
};

exports.flattenStrapiObject = flattenStrapiObject;
