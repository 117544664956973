import {
  Australia,
  ausRegionalCopy,
  ausTextTranslations,
  ausAddlConfig,
} from "./australia";

import {
  UnitedStates,
  usRegionalCopy,
  usTextTranslations,
  usAddlConfig,
} from "./unitedStates";

export enum RegionEnum {
  us = "us",
  au = "au",
}

export const countriesMap = {
  us: UnitedStates,
  au: Australia,
};

export const countriesCopyMap = {
  us: usRegionalCopy,
  au: ausRegionalCopy,
};

export const countriesTranslationsMap = {
  au: ausTextTranslations,
  us: usTextTranslations,
};

export const countriesAddlConfig = {
  au: ausAddlConfig,
  us: usAddlConfig,
};

// US version of emails - maps to a region property for region specific email(s)
export const regionalEmailsMap = {
  "support@liveouter.com": "supportEmail",
  "orders@liveouter.com": "ordersEmail",
  "team@liveouter.com": "teamEmail",
};

export const findCountryEmail = (
  email: string,
  region: RegionEnum | string
) => {
  if (regionalEmailsMap.hasOwnProperty(email)) {
    const emailKey = regionalEmailsMap[email];
    return countriesCopyMap[region][emailKey];
  }

  // by default return US support email
  return countriesCopyMap.us.supportEmail;
};

export const findCountryTel = (tel: string, region: RegionEnum | string) => {
  return countriesCopyMap[region].contactNumber;
};
