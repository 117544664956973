import React, { useContext } from "react";

import { shopifyVariant } from "../../shopify";

export const CartContext = React.createContext({
  state: {
    show: false,
    attached: false,
    checkoutId: "",
    loading: false,
    synced: false,
    errors: [] as CartError[],
    checkout: {} as any,
    promoCode: "",
    cartModalVisible: false,
    discountSelected: "",
    clydeLoading: true,
    purchaseTrackerItems: [] as {
      remoteId: string;
      price: number;
      quantity: number;
    }[],
  },
  actions: {
    addItem: async (_variant: shopifyVariant) => {},
    addItems: async (_variants: shopifyVariant[]) => {},
    removeItem: async (_variant: shopifyVariant) => {},
    removeCompleteItems: async (_variant: shopifyVariant[]) => {},
    toggle: () => {},
    applyPromo: async (_code: string) => {},
    onCheckout: async () => {},
    retryPromo: async () => {},
    createNewCart: async (_variables: any) => {},
    addItemWithQuantity: async (_variant: any, _quantity: number) => {},
    savePromo: async (_promoCode: string) => {},
    setCartModalVisible: async (_cartModalVisible: boolean) => {},
    setDiscountSelected: async (_discountSelected: string) => {},
    setCartLoading: async (_loading: boolean) => {},
    setClydeLoading: async (_loading: boolean) => {},
    addCartItems: async (_items: shopifyVariant[]) => {},
    setPurchaseTrackerItems: async (_item: {
      remoteId: string;
      price: number;
      quantity: number;
    }) => {},
    setInitPurchaseTrackerItems: () => {},
  },
});

export const useCartContext = () => useContext(CartContext);

export default CartContext;
