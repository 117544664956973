import React, { useEffect, useContext } from "react";
import { message } from "antd";
import * as _ from "lodash";

import {
  getLocaleFromPath,
  getRegionFromLocale,
} from "../../../plugins/gatsby-plugin-outer-i18n";
import * as LocalDb from "../../utils/localDb";
import * as routeUtil from "../../utils/routeHelpers";
import cloneDeep from "lodash/cloneDeep";
import ShowroomsContext from "../Showrooms/Showrooms.context";

import * as Analytics from "../../utils/analytics";

const STORE_KEY = "user";

export enum FinanceOption {
  Affirm = "affirm",
}

export interface UserProps {
  locale: {
    code: string;
    region: string;
  };
  financing?: FinanceOption;
}

export interface UserStateProps {
  user: UserProps;
  loading: boolean;
}

export interface DispatchUserStateActionsProps {
  (action: Record<string, unknown>): void;
}

export interface UserStateStoreProps {
  state: UserStateProps;
  dispatch: DispatchUserStateActionsProps;
  setUserLocale: (code: string) => void;
}

// HACK: this was added to support strapi i18n, it uses just en for US locale, other locales uses the standard.

const defaultState: UserStateProps = {
  user: {
    locale: { code: "en", region: "us" },
  },
  loading: true,
};

export const UserContext = React.createContext<UserStateStoreProps>({
  state: defaultState,
  dispatch: (a): void => {},
  setUserLocale: () => {},
});

export const useUserContext = () => React.useContext(UserContext);

export const userHasFinancing = () => {
  return !!useUserContext().state.user.financing;
};

export enum MessageType {
  INIT_USER,
  UPDATE_LOCALE,
  SET_LOADING,
}

const userReducer = (state = defaultState, action): UserStateProps => {
  switch (action.type) {
    case MessageType.INIT_USER:
      return { ...action.payload, loading: false };

    case MessageType.UPDATE_LOCALE:
      state = cloneDeep(state);
      state.user.locale = action.payload;
      state.loading = false;
      return state;

    case MessageType.SET_LOADING:
      state = cloneDeep(state);
      state.loading = action.payload;
      return state;

    default: {
      throw new Error(`Unhandled action type ${action.type}`);
    }
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, defaultState);
  const { setUserDefaultLocation } = useContext(ShowroomsContext);

  const { user, loading } = state;

  const setUserLocale = code => {
    const localDbUserData = LocalDb.read(STORE_KEY);
    if (
      state.user.locale.code === code &&
      localDbUserData?.locale?.code === code
    )
      return dispatch({ type: MessageType.SET_LOADING, payload: false });

    dispatch({ type: MessageType.SET_LOADING, payload: true });

    const newState = {
      ...state.user,
      locale: {
        ...state.user.locale,
        code,
        region: getRegionFromLocale(code),
      },
    };
    LocalDb.save(STORE_KEY, newState);
    Analytics.track("Locale Switch", { ...newState.locale });

    setUserDefaultLocation(getRegionFromLocale(code));
    dispatch({ type: MessageType.UPDATE_LOCALE, payload: newState.locale });
  };

  useEffect(() => {
    if (window !== undefined) {
      const pathLocale = getLocaleFromPath(window.location.pathname);
      return setUserLocale(pathLocale);
    }
  }, []);

  useEffect(() => {
    if (window !== undefined && !loading) {
      // go to home when locale change
      const pathLocale = getLocaleFromPath(window.location.pathname);
      if (pathLocale !== user.locale.code) {
        let url = `${window.location.origin}/${user.locale.code}`;

        if (user.locale.code === "en") {
          url = window.location.origin;
        }
        window.scrollTo(0, 0);
        message.loading("Loading...", 0);
        routeUtil.jsWindowRedirectReplace(url);
      }
      // @ts-ignore
      Analytics.identify(undefined, {
        localeCode: user.locale.code,
        localeRegion: user.locale.region,
      });
    }
  }, [user.locale]);

  return (
    <UserContext.Provider value={{ state, dispatch, setUserLocale }}>
      {children}
    </UserContext.Provider>
  );
};
